<template>
    <transition name="custom transition" enter-active-class="animate__animated animate__slideInRight animate__faster" leave-active-class="animate__animated animate__slideOutRight animate__faster">
        <div class="fixed w-full h-full right-0 top-0 flex justify-end" @click.stop="$sidebar.show = false" style="background: rgba(0, 0, 0, 0.5)" v-if="$sidebar.show && data.Sidebar">
            <div :class="data.Sidebar.Class" @click.stop="$sidebar.stop = true">
                <div class="flex justify-center py-4 border border-solid border-white border-t-0 border-r-0 border-l-0">
                    <img :src="data.Sidebar.Logo" :width="data.Sidebar.LogoWidth" />
                </div>
                <div class="block text-white p-5">
                    <ul>
                        <li class="p-2 border border-solid border-t-0 border-r-0 border-l-0 border-white cursor-pointer" v-for="(item, n) in data.Sidebar.Menu" @click="mainAction(item)" :key="n">
                            {{item.Name}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    computed: {
        data () {
            return this.company.Header
        }
    }
}
</script>
